<div bsModal #holdScheduleModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="holdScheduleModal" aria-hidden="true" [config]="{backdrop: 'static', keyboard: !saving}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #holdMedForm="ngForm" novalidate (ngSubmit)="save()">
                <div class="modal-header border-0 d-flex">
                    <div>
                        <h4 class="modal-title text-uppercase  text-primary font-weight-bolder">
                            Hold Schedule for {{time}}
                        </h4>

                        <h4 class="modal-title text-uppercase font-weight-bolder">

                        </h4>
                    </div>
                    <button type="button" (click)="close()" class="close" [disabled]="saving">
                        <i aria-hidden="true" class="ki ki-close"></i>
                    </button>
                </div>
                <div class="modal-body mt-n7">
                    <tabset>
                        <tab>
                            <ng-template tabHeading>
                                <span><i class="fas fa-pause pr-2"></i>Hold Med</span>
                            </ng-template>
                            <div class="row card card-custom">
                                <div class="card-body pt-5 pb-0">
                                    <div class="row">
                                        <div class="form-group col-md-12 col-sm-6">
                                            <label>Hold Reason</label>
                                            <div class="radio-inline">
                                                <label
                                                    class="btn btn-outline-secondary radio radio-outline radio-info ml-1">
                                                    <input type="radio" name="radios1" id="radios1" [value]="1"
                                                        [disabled]="viewdisbl"
                                                        [(ngModel)]="holdMedicationSchedule.holdReason">
                                                    <span></span>Abnormal Readings
                                                </label>
                                                <label
                                                    class="btn btn-outline-secondary radio radio-outline radio-primary">
                                                    <input type="radio" name="radios2" id="radios2"
                                                        [disabled]="viewdisbl"
                                                        [(ngModel)]="holdMedicationSchedule.holdReason" [value]="2">
                                                    <span></span>Waiting for Procedure
                                                </label>
                                                <label
                                                    class="btn btn-outline-secondary radio radio-outline radio-primary">
                                                    <input type="radio" name="radios3" id="radios3"
                                                        [disabled]="viewdisbl"
                                                        [(ngModel)]="holdMedicationSchedule.holdReason" [value]="3">
                                                    <span></span>Physician's order
                                                </label>
                                                <label
                                                    class="btn btn-outline-secondary radio radio-outline radio-warning">
                                                    <input type="radio" name="radios4" id="radios4"
                                                        [disabled]="viewdisbl"
                                                        [(ngModel)]="holdMedicationSchedule.holdReason" [value]="4">
                                                    <span></span>Other
                                                </label>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-6 col-sm-6">
                                            <label>Hold From<span class="text-danger">*</span></label>
                                            <input type="datetime" class="form-control"
                                                [bsConfig]="{dateInputFormat: 'MM/DD/YYYY'}" bsDatepicker
                                                [bsValue]="bsValue1" [dateCustomClasses]="dateCustomClasse"
                                                id="holdFrom" [minDate]="today" placeholder="MM/DD/YYYY" name="holdFrom"
                                                [disabled]="disblHold" [(ngModel)]="holdMedicationSchedule.holdFrom "
                                                (ngModelChange)="holdDateSelected()">
                                            <span class="text-danger"
                                                *ngIf="(_required==true) && (holdMedicationSchedule.holdFrom==null ||holdMedicationSchedule.holdFrom==undefined)">Required</span>
                                        </div>

                                        <div class="form-group col-md-6 col-sm-6">
                                            <label>Resume From<span class="text-danger">*</span></label>
                                            <input type="datetime" class="form-control"
                                                [bsConfig]="{dateInputFormat: 'MM/DD/YYYY'}" bsDatepicker
                                                [bsValue]="bsValue2" [dateCustomClasses]="dateCustomClasse"
                                                id="resumeFrom" [minDate]="today" placeholder="MM/DD/YYYY"
                                                name="resumeFrom" [disabled]="disblResum"
                                                [(ngModel)]="holdMedicationSchedule.resumeFrom"
                                                (ngModelChange)="resumeDateSelected()">
                                            <span class="text-danger"
                                                *ngIf="(_required==true) && (holdMedicationSchedule.resumeFrom==null ||holdMedicationSchedule.resumeFrom==undefined)">Required</span>
                                        </div>


                                        <div class="form-group col-sm-12">
                                            <label>Comments (required)<span class="text-danger">*</span></label>
                                            <textarea class="form-control" id="CommentsTextarea" [disabled]="viewdisbl"
                                                name="commentsTextarea" [(ngModel)]="holdMedicationSchedule.comment"
                                                rows="2" placeholder="Enter your comments here"></textarea>
                                            <span class="text-danger"
                                                *ngIf="(_required==true) && (holdMedicationSchedule.comment=='' ||holdMedicationSchedule.comment==null ||holdMedicationSchedule.comment==undefined)">Required</span>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </tab>
                        <tab *ngIf="false">
                            <ng-template tabHeading>
                                <span><i class="fas fa-history pr-2"></i>History</span>
                            </ng-template>

                            <div class="row card card-custom pb-0">
                                <div class="card-body pt-3">
                                    <div class="row">
                                        <div class="form-group col-12">
                                            <table class="table table-hover cursor-pointer">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Date Time</th>
                                                        <th scope="col">Hold Reason</th>
                                                        <th scope="col">Nurse Notified</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">12/12/21 09:00 PM</th>
                                                        <td>Abnormal Readings</td>
                                                        <td>Yes</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">12/12/21 10:10 AM</th>
                                                        <td>Abnormal Readings</td>
                                                        <td>Yes</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">12/12/21 6:00 AM</th>
                                                        <td>Other</td>
                                                        <td>Yes</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>
                    </tabset>
                </div>
                <div class="modal-footer">
                    <div class="row d-flex flex-grow-1">
                        <div class="flex-column flex-grow-1 text-right">
                            <button [disabled]="saving" type="button" class="btn btn-light-danger font-weight-bold mr-2"
                                (click)="close()">
                                {{"Close" | localize }}
                            </button>
                            <button [disabled]="saving" type="button" *ngIf="viewUpdt" (click)="save('Update')"
                                class="btn btn-primary font-weight-bold">Update</button>
                            <button [disabled]="saving" type="button" *ngIf="holdBtn" (click)="save('Hold')"
                                class="btn btn-warning font-weight-bold">Hold</button>
                            <button [disabled]="saving" type="button" *ngIf="resmBtn" (click)="save('Resume')"
                                type="button" class="btn btn-warning font-weight-bold">Resume</button>
                            <!-- <button *ngIf="status!='Hold'" type="submit" class="btn btn-primary font-weight-bold"
                                    [disabled]="!holdMedForm.form.valid">
                                <i class="fas fa-check pr-2"></i>
                                <span>{{"Save" | localize}}</span>
                            </button> -->
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>