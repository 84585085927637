import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Injector,
    Output,
    ViewChild,
} from "@angular/core";
import { AppConsts } from "@shared/AppConsts";
import { AppComponentBase } from "@shared/common/app-component-base";
import { ModalDirective } from "ngx-bootstrap/modal";
import { NgForm, FormControl, Validators } from "@angular/forms";
import { outputs } from "@syncfusion/ej2-angular-navigations/src/sidebar/sidebar.component";
import {
    CreateOrEditClient_Medication_Schedule_TimeDto,
    CreateOrEditHoldMedicationDto,
    EMarsServiceProxy,
    GetHoldMedicationForEditOutput,
    GetHoldScheduleTimeForEditOutput,
    HoldMedicationsServiceProxy,
    HoldScheduleTimesServiceProxy,
} from "@shared/service-proxies/service-proxies";
import * as moment from "moment";
import { HoldScheduleDialogComponent } from "../holdScheduleDialog/hold-schedule-dialog.component";
import { error } from "console";
import { finalize } from "@node_modules/rxjs/operators";

@Component({
    selector: "holdMediModalDialog",
    styleUrls: ["./hold-Medi-Dialog.component.css"],
    templateUrl: "./hold-medi-dialog.component.html",
})
export class HoldMediDialogComponent extends AppComponentBase {
    @ViewChild("holdMedModal", { static: true }) modal: ModalDirective;
    @ViewChild("holdMedForm", { static: true }) form: NgForm;
    @ViewChild("holdScheduleModalDialog", { static: true })
    holdScheduleModalDialog: HoldScheduleDialogComponent;
    @Output() saveHoldMed: EventEmitter<{
        clientMedicationId: number;
        status: string;
    }> = new EventEmitter<{ clientMedicationId: number; status: string }>();

    active = false;
    saving = false;
    meddata: any;
    status: string;
    currentStatus: string;
    medicationId;
    drugName: string;
    patientName: string;
    _required = false;
    medSchTime: GetHoldScheduleTimeForEditOutput[];
    _idx: number;
    clientData: any[] = [];
    SelectedNurseName: string = "";
    patientId;
    activeTab: string = "firstTab";
    clientMedicationId: any;
    resumedMedications: CreateOrEditHoldMedicationDto[];
    medSchTempObj = new GetHoldScheduleTimeForEditOutput();
    holdSchHistory: GetHoldScheduleTimeForEditOutput[];
    holdMedication = new CreateOrEditHoldMedicationDto();
    constructor(
        injector: Injector,
        private _holdMedicationsServiceProxy: HoldMedicationsServiceProxy,
        private _holdScheduleTimeServiceProxy: HoldScheduleTimesServiceProxy,
        private _EMarsServiceProxy: EMarsServiceProxy,
        private cd: ChangeDetectorRef
    ) {
        super(injector);
    }

    counter(i: number): any {
        return new Array(i);
    }

    show(clientMedicationId?: number): void {
        this.meddata;
        this.saving = false;
        this.drugName = this.meddata.drugDescription;
        this.patientName = this.meddata.patientsname;
        this.patientId = this.meddata.patientId;
        this.clientMedicationId = clientMedicationId;
        this.holdScheduleModalDialog.medicationId = clientMedicationId;
        this.resumeHoldMedicationsHistory(clientMedicationId);
        this._holdScheduleTimeServiceProxy
            .getClient_ScheduleTime_Hold(clientMedicationId)
            .subscribe((result) => {
                this.medSchTime = result;
            });
        this._holdScheduleTimeServiceProxy
            .getClient_ScheduleHistory(clientMedicationId)
            .subscribe((data) => {
                this.holdSchHistory = data;
            });
        if (this.status == "Approved") {
            this.holdMedication = new CreateOrEditHoldMedicationDto();
            this.holdMedication.clientMedicationId = clientMedicationId;
            this.modal.show();
        } else {
            this._holdMedicationsServiceProxy
                .getHoldMedicationForEdit(clientMedicationId)
                .subscribe((result) => {
                    if (result.holdMedication) {
                        if (result.holdMedication.isActive) {
                            this.holdMedication = result.holdMedication;
                            this.SelectedNurseName =
                                this.holdMedication.nurseNotifiedId;
                            this.cd.detectChanges();
                        }
                    }
                });

            this.modal.show();
        }
    }
    holdSchdlDialog(holdschedul) {
        this.holdScheduleModalDialog._mode = "holdOrRsm";
        this.holdScheduleModalDialog.viewResmBtn = true;
        this.holdScheduleModalDialog.status =
            holdschedul.holdScheduleTime.status;
        this.holdScheduleModalDialog.show(holdschedul);
    }
    editHoldSchdl(holdschedul) {
        this.holdScheduleModalDialog._mode = "edit";
        this.holdScheduleModalDialog.viewResmBtn = false;
        this.holdScheduleModalDialog.status =
            holdschedul.holdScheduleTime.status;
        this.holdScheduleModalDialog.show(holdschedul);
    }
    AddHold(holdScheduleTime) {
        if (
            this.holdSchHistory.some(
                (item) =>
                    item.scheduleTimeId == holdScheduleTime.scheduleTimeId &&
                    item.holdScheduleTime.status != "Resume"
            )
        ) {
            this.notify.warn("The Hold For this Schedule is already held");
            return;
        }
        this.holdScheduleModalDialog._mode = "add";
        this.holdScheduleModalDialog.status = holdScheduleTime.status;
        this.holdScheduleModalDialog.show(holdScheduleTime);
    }
    View(holdScheduleTime) {
        this.holdScheduleModalDialog._mode = "view";
        this.holdScheduleModalDialog.status = holdScheduleTime.status;
        this.holdScheduleModalDialog.show(holdScheduleTime);
    }
    searchNurse(event: any) {
        var trimmed = this.SelectedNurseName.trim();
        if (trimmed.length == 0) {
            this.clientData = null;
            this.clientData = [];
            this.cd.detectChanges();
        }
        let wordSearch = this.SelectedNurseName.trim();
        setTimeout(() => {
            if (wordSearch == this.SelectedNurseName.trim()) {
                if (this.SelectedNurseName.trim()) {
                    this._EMarsServiceProxy
                        .getassociatedHouseNurse(wordSearch, this.patientId)
                        .pipe(finalize(() => {}))
                        .subscribe((data) => {
                            this.clientData = data;
                            if (data.length == 0) this.SelectedNurseName = null;
                            this.cd.detectChanges();
                        });
                } else {
                    this.SelectedNurseName = null;
                }
            }
        }, 2000);
    }
    onSelect(event: any) {
        let data = event;
        if (data && data.userName) {
            this.SelectedNurseName = data.userName;
            this.holdMedication.nurseNotifiedId = data.userName;
        }
    }

    nurseNotifiedChecked(event: any) {
        if (event.currentTarget.checked) {
            this.SelectedNurseName = this.holdMedication.nurseNotifiedId;
        } else {
            this.SelectedNurseName = null;
            this.holdMedication.nurseNotifiedId = null;
        }
    }
    careTeamChecked(event: any) {
        if (event.currentTarget.checked) {
        } else {
            this.holdMedication.careTeamId = null;
        }
    }

    onNurseNameChange(value: string) {
        if (!value) {
            this.SelectedNurseName = null; // Set to null when cleared
            this.holdMedication.nurseNotifiedId = null; // Set to null when cleared
        }
    }

    resumeHoldMedicationsHistory(clientMedicationId) {
        this._holdMedicationsServiceProxy
            .getResumeMedicationHistory(clientMedicationId)
            .subscribe((res) => {
                this.resumedMedications = res;
            });
    }
    onShown(): void {}
    resumeHoldMedication() {
        this.message.confirm(
            "Are you sure you want to Resume the Hold Medication?",
            "The medication was previously placed on hold. Please confirm that you want to resume it now.",
            (isConfirmed) => {
                if (isConfirmed) {
                    this.holdMedication.isActive = false;
                    this.currentStatus = "Approved";
                    this.save();
                }
            }
        );
    }

    HoldMedication() {
        this.holdMedication.isActive = true;
        this.currentStatus = "Hold";
        this.save();
    }
    onTabChange(tabId: string) {
        this.activeTab = tabId;
    }

    save(): void {
        if (
            this.holdMedication.comment == " " ||
            this.holdMedication.comment == undefined ||
            this.holdMedication.holdFrom == null ||
            this.holdMedication.holdFrom == undefined ||
            this.holdMedication.resumeForm == null ||
            this.holdMedication.resumeForm == undefined ||
            (this.holdMedication.nurseNotified &&
                (this.holdMedication.nurseNotifiedId == null ||
                    this.holdMedication.nurseNotifiedId == undefined ||
                    this.holdMedication.nurseNotifiedId == "")) ||
            (this.holdMedication.careTeamNotified &&
                (this.holdMedication.careTeamId == null ||
                    this.holdMedication.careTeamId == undefined ||
                    this.holdMedication.careTeamId == ""))
        ) {
            this._required = true;
        } else {
            // this.holdMedication.holdFrom = (this.holdMedication.holdFrom);
            // this.holdMedication.resumeForm = (this.holdMedication.resumeForm);
            // this.holdMedication.holdSchedualTimeId = this.medSchTime
            this.holdMedication.resumeFromScheduleTimeId;
            this.saving = true;
            this._holdMedicationsServiceProxy
                .createOrEdit(this.holdMedication)
                .subscribe(
                    (result) => {
                        this.emitHoldEventForList(
                            this.clientMedicationId,
                            this.currentStatus
                        );
                        this.close();
                        this.notify.info(this.l("SavedSuccessfully"));
                    },
                    (error) => {
                        this.saving = false;
                    }
                );
        }
    }

    emitHoldEventForList(clientMedicationId: string, status: string) {
        this.saveHoldMed.emit({
            clientMedicationId: this.clientMedicationId,
            status: status,
        });
    }
    close(): void {
        this.clear();
        this.active = false;
        this.modal.hide();
    }
    clear() {
        this._required = false;
        this.resumedMedications = [];
        this.SelectedNurseName = "";
        this.holdMedication = new CreateOrEditHoldMedicationDto();
        this.medSchTime = [];
        this.holdSchHistory = [];
    }
    getHoldSchedulMed(medicationId) {
        this.show(medicationId);
    }
}
